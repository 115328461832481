import React from "react";
import styles from "./styles.module.scss";
import logo from "../../assets/profile.png";
import clsx from "clsx";

const Navbar = () => {
  return (
    <nav className={styles.nav}>
      <div className={clsx(styles.navContainer, "section")}>
        <div className={styles.navHeader}>
          <img src={logo} className={styles.logo} alt="" />
        </div>
        <ul className={styles.navLinks}>
          <li>
            <a href="#about" className={styles.link}>
              about
            </a>
          </li>
          {/* <li>
            <a href="#projects" className={styles.link}>
              projects
            </a>
          </li> */}
          <li>
            <a href="#contact" className={styles.link}>
              contact
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
