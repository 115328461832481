import clsx from "clsx";
import React from "react";
import styles from "./styles.module.scss";

const Footer = () => {
  return (
    <div className={styles.footerSection}>
      <span className={clsx(styles.footerContent)}>
        &copy; {new Date().getFullYear()} Cathy Dao
      </span>
    </div>
  );
};

export default Footer;
