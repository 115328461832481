import { useForm, ValidationError } from "@formspree/react";
import { useState } from "react";
import styles from "./styles.module.scss";
import clsx from "clsx";
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = () => {
  const [captchaDone, setCaptchaDone] = useState(false);
  const [captchaError, setCaptchaError] = useState(false);
  const [state, handleSubmit] = useForm("xrgverov");

  const handleChange = () => {
    setCaptchaDone(true);
    setCaptchaError(false);
  };

  const handleClick = () => {
    setCaptchaError(true);
  };

  return (
    <div>
      {state.succeeded && (
        <div>
          <p className={styles.confirmation}>
            Your message has been received. Thank you!
          </p>
        </div>
      )}
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.formRow}>
          <label className={styles.label} htmlFor="name">
            Name
          </label>
          <input
            className={styles.input}
            id="name"
            type="name"
            name="name"
            required
          />
          <ValidationError prefix="Name" field="name" errors={state.errors} />
        </div>
        <div className={styles.formRow}>
          <label className={styles.label} htmlFor="email">
            Email
          </label>
          <input
            className={styles.input}
            id="email"
            type="email"
            name="email"
            required
          />
          <ValidationError prefix="Email" field="email" errors={state.errors} />
        </div>
        <div className={styles.formRow}>
          <label className={styles.label} htmlFor="message">
            Message
          </label>
          <textarea
            className={styles.message}
            id="message"
            name="message"
            required
          />
          <ValidationError
            prefix="Message"
            field="message"
            errors={state.errors}
          />
        </div>
        <div
          className={!captchaError ? styles.captchaHide : styles.captchaDisplay}
        >
          reCAPTCHA Failed. Please try again.
        </div>

        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          onChange={handleChange}
          className={styles.captchaBox}
        />
        <div className={styles.btnContainer}>
          {captchaDone ? (
            <button
              className={clsx(styles.btn, "btn")}
              type="submit"
              disabled={state.submitting}
            >
              Send
            </button>
          ) : (
            <button
              className={clsx(styles.btn, "btn")}
              onClick={handleClick}
              type="button"
            >
              Send
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
