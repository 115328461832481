import { Contact, Footer, Landing, Navbar, Projects } from "./components";
function App() {
  return (
    <div>
      <Navbar />
      <Landing />
      {/* <Projects /> */}
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
