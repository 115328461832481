import clsx from "clsx";
import React from "react";
import styles from "./styles.module.scss";
import landingPhoto from "../../assets/landing.png";

const Landing = () => {
  return (
    <main id="about" className={clsx("page", styles.pageWrapper)}>
      <section className={styles.curveContainer}>
        <div className={styles.curve}></div>
      </section>

      <div className={clsx(styles.landingSection, "section")}>
        <div className={clsx(styles.landing)}>
          <div className={styles.landingContent}>
            <span className={styles.text}>Front End Developer</span>
            <h1 className={clsx(styles.title)}>Hi, I'm Cathy</h1>
            <p className={styles.info}>
              I am a Front End Developer based in Los Angeles, California. I
              build websites with a focus on accessibility and responsive
              design.
            </p>
            <div className={styles.btnContainer}>
              <a
                href="https://github.com/cathydao95"
                target="_blank"
                rel="noreferrer"
                className={clsx(styles.gitHubBtn, "btn")}
              >
                Github
              </a>
              <a
                href="https://www.linkedin.com/in/cathy-dao/"
                target="_blank"
                rel="noreferrer"
                className={clsx(styles.linkedInBtn, "btn")}
              >
                LinkedIn
              </a>
            </div>
          </div>
          <div className={styles.imgContainer}>
            <img src={landingPhoto} alt="test img" className={styles.img} />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Landing;
