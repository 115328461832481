import React from "react";
import styles from "./styles.module.scss";
import clsx from "clsx";
import ContactForm from "../Form/Form";

const Contact = () => {
  return (
    <div id="contact" className={clsx(styles.contactSection, "center")}>
      <div className="section">
        <div className="header">
          <h2 className="title">Contact Me</h2>
          <div className="underline"></div>
        </div>

        <div className={styles.formContainer}>
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

export default Contact;
